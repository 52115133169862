import MainLayout from "./MainLayout";
import UserService from "../../Services/UserList";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import Lottie from "lottie-react";
import animationData from "../../animations/loader_animation0.json";
import Loader from "../utils/Loader";

const Log = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [empCodeFilter, setEmpCodeFilter] = useState("");
  const [nameFilter, setNameFilter] = useState("");

  const userLog = async () => {
    try {
      setLoading(true);
      const response = await UserService.loglist();
      setLoading(false);
      setData(response.data);
      console.log(response);
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: error.message || "Something went wrong!",
        timer: 3000, // Automatically close after 3 seconds
      });
    }
  };

  useEffect(() => {
    userLog();
  }, []);

  const cleanedTimestamp = (timestamp) => {
    return timestamp.replace(/\.000000Z$/, "");
  };

  // Filtered data based on empCode and name
  const filteredData = data.filter((item) => {
    const empCodeMatch =
      empCodeFilter === "" ||
      (item.empCode && item.empCode.includes(empCodeFilter));
    const nameMatch =
      nameFilter === "" ||
      (item.name && item.name.toLowerCase().includes(nameFilter.toLowerCase()));
    return empCodeMatch && nameMatch;
  });

  return (
    <MainLayout>
      {loading ? (
        <div className="loader">
          <Loader />
        </div>
      ) : (
        <div>
          <div className="filter mb-4 mt-4">
            {" "}
            {/* Added margin-bottom */}
            <input
              type="text"
              placeholder="Filter by EmpCode"
              value={empCodeFilter}
              onChange={(e) => setEmpCodeFilter(e.target.value)}
              className="border p-2 mr-2" // Added margin-right for spacing
            />
            <input
              type="text"
              placeholder="Filter by Name"
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
              className="border p-2" // No margin-right needed for the last input
            />
          </div>
          <table className="w-full border border-collapse mt-0 productTable">
            <thead>
              <tr>
                <th className="w-40 bg-blue-950 p-2 text-white">Sr.No</th>
                <th className="w-40 bg-blue-950 p-2 text-white">EmpCode</th>
                <th className="w-40 bg-blue-950 p-2 text-white">Name</th>
                <th className="w-40 bg-blue-950 p-2 text-white">Ip</th>
                <th className="w-40 bg-blue-950 p-2 text-white">Date</th>
                <th className="w-40 bg-blue-950 p-2 text-white">Time</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item, index) => (
                <tr key={item.id}>
                  <td className="border p-1 text-center">{index + 1}</td>
                  <td className="border p-1 text-center">{item.empCode}</td>
                  <td className="border p-1 text-center">{item.name}</td>
                  <td className="border p-1 text-center">{item.ip}</td>
                  <td className="border p-1 text-center">
                    {item
                      ? new Date(item.created_at).toLocaleDateString("en-GB")
                      : ""}
                  </td>
                  <td className="border p-1 text-center">
                    {new Date(item.updated_at).toLocaleString("en-GB", {
                      // day: '2-digit',
                      // month: '2-digit',
                      // year: 'numeric',
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: false,
                    })}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </MainLayout>
  );
};

export default Log;
