import MainLayout from "./MainLayout";
import UserService from "../../Services/UserList";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";
import animationData from "../../animations/loader_animation0.json";
import axios from "axios";
import Loader from "../utils/Loader";
const HelpDeskList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const helpdesk = async () => {
    try {
      setLoading(true);
      const response = await UserService.helpDeskList();
      setLoading(false);
      setData(response.data);
      console.log(response);
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "warning",
        title: "warning",
        text: error,
        timer: 3000, // Automatically close after 3 seconds
      });
    }
  };
  const change_status = async (e, id) => {
    const status = e.target.value;
    const data = {
      status,
      id,
    };
    try {
      setLoading(true);
      const user = localStorage.getItem("user");
      const headers = {
        "Content-Type": "application/json", // Example header
        Authorization: "Bearer " + user, // Example Authorization header
      };

      const response = await axios.post(
        "https://quatationapis.groheapp.in/api/admin/userlist/help/desk/status",
        data,
        { headers }
      );

      // const response = await UserService.changeStatus(data);
      setLoading(false);
      setData(response.data.data);
    } catch (error) {
      setLoading(false);
      navigate("/admin/userlist");
      Swal.fire({
        icon: "warning",
        title: "warning",
        text: error,
        timer: 3000, // Automatically close after 3 seconds
      });
    }
  };
  useEffect(() => {
    helpdesk();
  }, []);
  return (
    <>
      <MainLayout>
        {loading ? (
          <div className="loder">
            <Loader />
          </div>
        ) : (
          <table className="w-full border border-collapse mt-0 productTable">
            <thead>
              <tr>
                <th className="w-40 bg-blue-950 p-2 text-white">Ticket Id</th>
                <th className="w-40 bg-blue-950 p-2 text-white">Email</th>
                <th className="w-40 bg-blue-950 p-2 text-white">Categoery</th>
                <th className="w-40 bg-blue-950 p-2 text-white">Status</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item) => (
                  <tr>
                    <td className="border p-1 text-center">{item.id}</td>
                    <td className="border p-1 text-center">{item.email}</td>
                    <td className="border p-4 text-center">
                      {item.suggestion}
                    </td>
                    {/* <td className="border p-4 text-center">{item.status}</td> */}
                    <td className="text-center">
                      <select
                        name=""
                        onChange={(e) => change_status(e, item.id)}
                        className="w-full"
                        value={item.status}
                      >
                        <option value="open">Open</option>
                        <option value="close">Close</option>
                      </select>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </MainLayout>
    </>
  );
};
export default HelpDeskList;
