
import Request from "../Helpers/Request";
const UserList=async()=>{
    return await Request("admin/userlist",'','get');
}
const EditUser= async(data)=>{
    return await Request("admin/userlist/edit/" + data,'','get');
}
const UpdateUser = async(data)=>{
    return await Request("admin/userlist/update" , data);
}
const getprofile = async(data)=>{
    return await Request("profile",'','get');
}
const helpDeskList = async(data)=>{
    return await Request("admin/userlist/help/desk/list",'','get');
}
const changeStatus = async(data) => {
    return await Request("admin/userlist/help/desk/status/" , data);
    
  };
  const loglist = async(data)=>{
    return await Request("admin/log",'','get');
}
const UserService={
    UserList,
    EditUser,
    UpdateUser,
    getprofile,
    helpDeskList,
    changeStatus,
    loglist,
}
export default UserService