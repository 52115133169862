import { useState } from "react";
import UserSection from "../../Services/User/UserLogin";
import Swal from "sweetalert2";
import { handleApiError } from "../../Helpers/handleApiError ";
import UserTopbar from "../UserTopbar";
const AddClient = () => {
    const [formData, setFormData] = useState({
        client_name: '',
        site_add: '',
        afd: '',
        add_to: '',
        username: '',
    });
    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const response = await UserSection.addClient(formData);
            if (response.status) {
                Swal.fire({
                    icon: 'success',
                    title: 'client',
                    text: 'Client added  successfully',
                    timer: 3000, // Automatically close after 3 seconds
                });
                setFormData({
                    client_name: '',
                    site_add: '',
                    afd: '',
                    add_to: '',
                    username: '',
                });
                // ClientList();
            }
        } catch (error) {
            handleApiError(error, 'Failed to fetch data. Please try again later.');
        }
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <>
        <UserTopbar heading="View Projects" />
        <section className="md:flex justify-center py-6 px-4">
            <div className="flex-1 max-w-4xl bg-white p-6 rounded-lg shadow-lg">
                <h2 className="text-2xl font-semibold text-gray-800 mb-6">Add New Client Details</h2>
                <form action="" method="post">
                    <div className="mb-4">
                        <label htmlFor="client_name" className="block text-sm font-medium text-gray-700 mb-2">Client Name</label>
                        <input 
                            className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
                            type="text" 
                            id="client_name" 
                            name="client_name" 
                            placeholder="Enter client name" 
                            value={formData.client_name} 
                            onChange={handleChange} 
                        />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="site_add" className="block text-sm font-medium text-gray-700 mb-2">Site Address</label>
                        <input 
                            className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
                            type="text" 
                            id="site_add" 
                            name="site_add" 
                            placeholder="Enter site address" 
                            value={formData.site_add} 
                            onChange={handleChange} 
                        />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="afd" className="block text-sm font-medium text-gray-700 mb-2">Architect Firm Details</label>
                        <input 
                            className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
                            type="text" 
                            id="afd" 
                            name="afd" 
                            placeholder="Enter architect firm details" 
                            value={formData.afd} 
                            onChange={handleChange} 
                        />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="add_to" className="block text-sm font-medium text-gray-700 mb-2">Address To</label>
                        <input 
                            className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
                            type="text" 
                            id="add_to" 
                            name="add_to" 
                            placeholder="Enter address" 
                            value={formData.add_to} 
                            onChange={handleChange} 
                        />
                    </div>

                    <div className="flex justify-end">
                    <button onClick={handleSubmit} type="button" className="bg-blue-950 p-3 text-white rounded">Add New Client</button>
                    </div>
                </form>
            </div>
        </section>
        </>
    );
};

export default AddClient;
