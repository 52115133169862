import { Box, keyframes } from '@mui/material';
import React from 'react';

const bounceAnimation = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
`;

const Loader = () => {
    return (
        <Box
            sx={{
                display: "flex",
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 999,
            }}
        >

            <Box
                sx={{
                    width: "70px",
                    height: "70px",
                    borderRadius: "20px",
                    backgroundColor: "orange",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        gap: "10px",
                    }}
                >
                    {[...Array(3)].map((_, index) => (
                        <Box
                            key={index}
                            sx={{
                                width: "10px",
                                height: "10px",
                                backgroundColor: "white",
                                borderRadius: "50%",
                                animation: `${bounceAnimation} 1.4s ease-in-out ${index * 0.2}s infinite`,
                            }}
                        />
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

export default Loader;
