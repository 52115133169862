import UserTopbar from "../UserTopbar";
import Products from "./Products";
import Summary from "./Summary";
import UserProducts from "./UserProjects";
import ViewProject from "./ViewProject";
import ViewProjects from "./ViewProjects";

const UserHomepage = ({ activeLink }) => {
  return (
    <>
      <UserTopbar heading="Homepage" />
      <main className="w-full">
        <img
          src="https://grohelogin.ruminateitsolution.com/2N.jpg"
          alt="homepage"
          width={100}
          className="w-full h-full"
        />
      </main>
    </>
  );
};

export default UserHomepage;
