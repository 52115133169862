import { useEffect, useState } from "react";
import UserSection from "../../Services/User/UserLogin";
import { Link } from "react-router-dom";
import UserTopbar from "../UserTopbar";
import animationData from "../../animations/loader_animation0.json";
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";
import { FaDownload } from "react-icons/fa";
import { FaFilePdf } from "react-icons/fa";
import DownloadSummary from "./DownloadSummary";
import Downloadpdf from "./Downloadpdf";
import Loader from "../utils/Loader";
// import { handleApiError } from "../../Helpers/handleApiError ";
const Summary = () => {
  const navigate = useNavigate();
  const [sectionArr, setSectionArr] = useState([]);
  const [projectId, setprojectId] = useState();
  const [pdfId, setpdfId] = useState();
  const [projectDatas, setprojectData] = useState();
  const [sectionTotal, setSectionTotal] = useState(0);
  const [showComponent, setshowComponent] = useState(false);
  const [showPdfComponent, setshowPdfComponent] = useState(false);
  const [pdfloading, setPdfLoading] = useState(false);
  const [excelloading, setExcelLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisable] = useState(false);
  const [dataFromDownloadSummary, setDataFromDownloadSummary] = useState("");

  const handleDataFromDownloadSummary = (data) => {
    console.log("data from child" + data);
    setDataFromDownloadSummary(data);
  };
  console.log("xyz " + dataFromDownloadSummary);

  useEffect(() => {
    if (dataFromDownloadSummary == "downloaded") {
      setExcelLoading(false);
    }
  }, [dataFromDownloadSummary]);

  useEffect(() => {
    projectData();
  }, []);
  useEffect(() => {
    // Set loading to true when component mounts
    setLoading(true);

    // Set loading to false after one minute
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // 60000 milliseconds = 1 minute

    // Clear the timer when the component unmounts or the dependency changes
    return () => clearTimeout(timer);
  }, []);
  const [project, setProject] = useState([
    {
      user_id: "",
      client_id: "",
      finish: "",
      area: "",
      total: "",
      project_id: "",
    },
  ]);
  useEffect(() => {
    if (showComponent) {
      setLoading(true); // Show loading message when component is shown
      // Simulate loading delay (remove setTimeout in real use case)
      setTimeout(() => {
        setLoading(false);
      }, 2000); // Example: Simulating a 2-second loading time
    }
  }, [showComponent]);

  const projectData = async () => {
    try {
      setLoading(true);
      const response = await UserSection.projectData();
      if (response.status) {
        // console.log(response);
        setProject(response.data);
        // console.log(project);
      }
      setLoading(false);
    } catch (error) {
      // handleApiError(error, 'Failed to fetch data. Please try again later.');
    }
  };
  const editProject = (id) => {
    navigate(`/user/projects/${id}`);
  };
  const downloadexcel = async (id) => {
    try {
      //   setExcelLoading(true);
      setprojectId(id);
      //   setTimeout(() => {
      //     setExcelLoading(false);
      //   }, 1000);
    } catch (error) {
      console.log(error);
    }
  };
  const downloadpdf = async (id) => {
    try {
      setPdfLoading(true);
      setpdfId(id);

      setTimeout(async () => {
        setPdfLoading(false);
      }, 1000);
    } catch (error) {
      setLoading(true);
      console.log(error);
    }
  };
  return (
    <>
      <UserTopbar heading={`Project Summary`} />
      {loading ? (
        <div class="loder">{<Loader />}</div>
      ) : (
        <table class="min-w-full border border-gray-300 divide-y divide-gray-300">
          <thead>
            <tr>
              <th class="border bg-blue-950 text-white p-2 font-semibold">
                Download
              </th>
              <th class="border bg-blue-950 text-white p-2 font-semibold">
                Project ID
              </th>
              <th class="border bg-blue-950 text-white p-2 font-semibold">
                Client ID
              </th>
              <th class="border bg-blue-950 text-white p-2 font-semibold">
                Client Name
              </th>
              <th class="border bg-blue-950 text-white p-2 font-semibold">
                Date
              </th>
              <th class="border bg-blue-950 text-white p-2 font-semibold">
                Action
              </th>
              {/* <th class="border bg-blue-950 text-white p-2 font-semibold">Total</th> */}
              {/* <th class="border p-2 bg-blue-500 text-white font-semibold">Action</th> */}
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-300">
            {project.map((row) => (
              <tr key={row.id}>
                <td className="border p-2 text-center">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {excelloading && <Loader />}
                    <FaDownload
                      className="hover:cursor-pointer"
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        downloadexcel(row.project_id);
                        setExcelLoading(true);
                      }}
                    />

                    {/* {pdfloading && row.project_id == pdfId? (
                                         <Lottie
                                         animationData={animationData}
                                         loop={true}
                                         autoplay={true}
                                         style={{ height: '30px', width: '30px'}}
                                     />
                                      ):(
                                        <FaFilePdf onClick={() => downloadpdf(row.project_id)} />
                                    )} */}
                  </div>
                </td>
                <td className="border p-2 text-center">{row.project_id}</td>
                <td className="border p-2 text-center">{row.client_id}</td>
                <td className="border p-2 text-center">{row.client_name}</td>
                <td className="border p-2 text-center">
                  {new Date(row.created_at).toLocaleDateString("en-GB")}
                </td>
                <td className="border p-2 text-center">
                  <button
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                    onClick={(e) => editProject(row.id)}
                  >
                    Edit
                  </button>
                </td>

                {/* <Link to={`/user/project/edit/${row.id}`}  ="text-blue-500 font-semibold hover:underline border p-4 text-center">Edit </Link> */}
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <div style={{ display: showComponent ? "block" : "none" }}>
        <DownloadSummary
          project={projectId}
          sendData={handleDataFromDownloadSummary}
        />
      </div>
      {/* <div style={{ display: showComponent ? 'block' : 'none' }}>
                <Downloadpdf project={pdfId} />
            </div> */}
    </>
  );
};

export default Summary;
