import axios from './Axios';
import Cookies from "js-cookie";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Request = (url, requestPayload = {}, type = "post") => new Promise((resolve, reject) => {
    // const user = JSON.parse(Cookies.get('token'));
    if (type === "get") {
        axios.get(url)
            .then((data) => resolve(data.data))
            .catch((err) => {
                if (err.response?.status === 401) {
                    Cookies.remove('token');
                    // window.location.href = '/admin/login';
                }
                if (err.message === "Network Error") {

                    toast.error("Network Error: Please check your internet connection", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
                reject(err);
            });
    }
    else {
        axios.post(url, requestPayload)
            .then((data) => resolve(data.data))
            .catch((err) => {
                if (err.response?.status === 401) {
                    Cookies.remove('token');
                    // window.location.href = '/admin/login';
                }
                if (err.message === "Network Error") {

                    toast.error("Network Error: Please check your internet connection", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
                reject(err);
            });
    }
});
export default Request;
