import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
const AdminLogin = ({children}) => {
    const navigate = useNavigate();
const [data, setData] = useState();
useEffect(() => {
    Login();
}, []) 
    const Login = async () => {
        try {
            const user = localStorage.getItem("user");
            const headers = {
                'Content-Type': 'application/json', // Example header
                Authorization: 'Bearer ' + user, // Example Authorization header
            };
            const response = await axios.get('https://quatationsapis.groheapp.in/public/api/profile', { headers });
            // const response = await axios.get('https://quatationapis.groheapp.in/api/profile', { headers });
            if ((response.status) && (response.data.data.role_id == 1) && response.data.data.is_active == 1) {
                setData(response.status);
            }
            else {
                localStorage.removeItem("user");
                navigate('/admin/login');
            }
        } catch (error) {
            navigate('/admin/login');
        }
    }
    if (data) {
        return children;
    }
    else {
        navigate('/admin/login');
    }
}
export default AdminLogin;