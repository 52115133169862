import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import UserService from "../Services/UserList";
import Swal from "sweetalert2";
import MainLayout from "./admin/MainLayout";
import Lottie from "lottie-react";
import animationData from "../animations/loader_animation0.json";
import Loader from "./utils/Loader";
export default function AdminUserList({ updateUserList }) {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    userlistapi();
  }, []);
  const userlistapi = async () => {
    try {
      setLoading(true);
      const response = await UserService.UserList();
      setUsers(response.data.data);
      setLoading(false);
    } catch (error) {
      Swal.fire({
        icon: "warning",
        title: "warning",
        text: error,
        timer: 3000, // Automatically close after 3 seconds
      });
    }
  };
  let active = "<span>Active</span>";
  let deactive = "<span>Deactive</span>";
  return (
    <MainLayout>
      {loading ? (
        <div className="loder">
          <Loader />
        </div>
      ) : (
        <table className=" productTable w-full border border-collapse mt-0">
          <thead>
            <tr>
              <th className="w-40 bg-blue-950 p-2 text-white">Id</th>
              <th className="w-40 bg-blue-950 p-2 text-white">Name</th>
              <th className="w-40 bg-blue-950 p-2 text-white">Last Name</th>
              <th className="w-40 bg-blue-950 p-2 text-white">email</th>
              <th className="w-40 bg-blue-950 p-2 text-white">Emp Code</th>
              <th className="w-40 bg-blue-950 p-2 text-white">Status</th>
              <th className="w-40 bg-blue-950 p-2 text-white">Action</th>
            </tr>
          </thead>
          <tbody>
            {users.map((item, index) => (
              <tr>
                <td className="border p-2 text-center">{index + 1}</td>
                <td className="border p-2 text-center">{item.name}</td>
                <td className="border p-2 text-center">{item.last_name}</td>
                <td className="border p-2 text-center">{item.email}</td>
                {/* <td className="border p-2 text-center">{item.city}</td>
                                <td className="border p-2 text-center">{item.state}</td> */}
                <td className="border p-2 text-center">{item.emp_code}</td>
                <td className="border p-2 text-center">
                  {item.is_active == 1 ? "Active" : "Decative"}
                </td>
                {/* <td className="border p-2 text-center">{item.is_active}</td> */}

                <td className=" p-1 text-center">
                  <Link
                    to={`/admin/edit/${item.id}`}
                    className=" bg-blue-950 p-1 pr-2 text-white"
                  >
                    Edit{" "}
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </MainLayout>
  );
}
