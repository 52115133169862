import React, { useState, useEffect } from "react";
import SidebarNavigation from "../SidebarNavigation";
import { handleApiError } from "../../Helpers/handleApiError ";
import UserSection from "../../Services/User/UserLogin";
import Swal from "sweetalert2";
import UserTopbar from "../UserTopbar";
import { useRef } from "react";
import Lottie from "lottie-react";
import animationData from "../../animations/loader_animation0.json";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Autocomplete from "./Autocomplete";
import { get } from "svelte/store";
import * as XLSX from "xlsx";
import { Box, CircularProgress, LinearProgress, Tooltip } from "@mui/material";
import Select from "react-select";
import Loader from "../utils/Loader";

const UserProjects = () => {
  const { projectId } = useParams();
  const [client, setClient] = useState();
  const navigate = useNavigate();

  const [selectionId, setSelectionID] = useState(1);
  const [selectionClient, setselectionClient] = useState();
  const [secName, setSecName] = useState("");
  const [loading, setLoading] = useState(false);
  const [rows_id, setRowsId] = useState(1);
  const [files, setFiles] = useState(null);
  const [upload, setupload] = useState(false);
  const [error, setError] = useState("");
  const [excelData, setExcelData] = useState([]);
  const [tableData, setTableData] = useState([
    {
      id: rows_id,
      check: false,
      sku_code: "",
      brand: "",
      brand_description: "",
      finish: "",
      image: "",
      quantity: "",
      area: "null",
      mrp: "",
      discount_price: "",
      discount_per: "",
      gst: "",
      total: "",
      discount: "",
      selection: selectionId,
      active: true,
      selection_name: null,
      client_id: null,
      type: "add",
      check_gst: "",
    },
  ]);
  const [clientId, setclientId] = useState();
  const [data, setData] = useState("");
  const [selection, setSelection] = useState();
  const [selectedOption, setSelectedOption] = useState("Select Client");

  const [amountType, setAmountType] = useState("price");
  const [discountPer, setDiscountPer] = useState();
  const [sub_Total, setSub_Total] = useState(0);
  const [gst, setGst] = useState(1);
  const [name, setName] = useState();
  const [excludeGst, setexcludeGst] = useState(false);
  const [includeGst, setincludeGst] = useState(false);
  const [formData, setFormData] = useState({
    client_name: "",
    site_add: "",
    afd: "",
    add_to: "",
    username: "",
  });
  const [selectedCheckbox, setSelectedCheckbox] = useState([]);
  const [items, setItems] = useState([
    {
      id: 1,
      check: false,
      sku_code: "",
      brand: "",
      brand_description: "",
      finish: "",
      image: "",
      quantity: "",
      area: "",
      mrp: "",
      discount_price: "",
      discount_per: "",
      gst: "",
      total: "",
      discount: "",
      selection: "",
    },
  ]);
  const dropdownRef = useRef(null);
  const clientRef = useRef(null);
  const selectionNameref = useRef();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    ClientList();
    Selection();
    // editProject();
  }, []);

  // smart search
  const handleSelected = async (selected) => {
    const filteredItems = [...tableData];
    const updatedItems = filteredItems.filter((item) => item.sku_code !== "");
    const index = updatedItems.length;
    let hasgst;
    if (gst == 1) {
      hasgst = selected.gst;
    } else {
      hasgst = 0;
    }

    let ref_total = parseFloat((hasgst * selected.mrp) / 100);
    ref_total += parseFloat(selected.mrp);
    const getLartgest_Id = getLartgestId();
    const resdata = {
      id: getLartgest_Id,
      check: false,
      sku_code: selected.sku_code,
      brand: selected.brand,
      product_desc: selected.product_desc,
      finish: selected.finish,
      image: selected.image,
      quantity: "",
      area: "null",
      mrp: selected.mrp,
      discount_price: "",
      discount_per: "",
      gst: hasgst,
      total: "",
      discount: 0,
      selection: selectionId,
      active: true,
      selection_name: secName,
      check_gst: selected.gst,
      total: ref_total,
    };

    updatedItems[index] = resdata;

    // If there are still items left after filtering

    setTableData(updatedItems);
    setRowsId(rows_id + 1);
    console.log(tableData);
  };

  const removeRowsWithEmptySkuCode = async () => {
    // Filter out rows with empty sku_code
    const updatedTableData = tableData.filter((row) => {
      const isEmptySkuCode = row.sku_code.trim() === ""; // Check if sku_code is empty or contains only whitespace
      if (isEmptySkuCode) {
        console.log("Removing row with empty sku_code:", row);
      }
      return !isEmptySkuCode; // Keep rows that do not have an empty sku_code
    });

    // Update the state with the filtered tableData
    setTableData([]);
    setTableData((prevTableData) => updatedTableData);
  };

  // Call the function whenever you need to remove rows with empty sku_code

  //edit project data
  //   useEffect(() => {
  //     const fetchData = async () => {
  //       try {
  //         const response = await UserSection.editProject(projectId);
  //         const newData = [];
  //         setselectionClient(response.data[0].client_id);
  //         setSecName(response.data[0].secname);
  //         response.data.forEach((item, index) => {
  //           const productData = item.product_data[index];
  //           newData.push({
  //             id: item.project_id,
  //             check: false,
  //             sku_code: item.sku_code,
  //             brand: productData.product.brand,
  //             product_desc: productData.product.product_desc,
  //             finish: productData.product.finish, // Assuming 'finish' is directly accessible from product_data
  //             image: productData.product.image, // You can set the default value or access it if available
  //             quantity: item.qty,
  //             area: item.area,
  //             mrp: productData.product.mrp,
  //             discount_price: productData.disc_price,
  //             discount_per: productData.disc_per,
  //             gst: productData.gst,
  //             total: productData.total, // You can set the default value or access it if available
  //             discount: "", // You can set the default value or access it if available
  //             selection: item.section_id,
  //             active: item.section_id == selectionId ? true : false,
  //             selection_name: item.secname,
  //             client_id: item.client_id,
  //             type: "update",
  //           });
  //         });
  //         setTableData([]);
  //         setTableData(newData);
  //         console.log(newData);
  //       } catch (error) {
  //         // Handle errors if any
  //         console.error("Error fetching data:", error);
  //       }
  //     };

  //     fetchData(); // Call the inner async function immediately
  //   }, [projectId, upload]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // await setLoading(true);
        const response = await UserSection.editProject(projectId);
        const newData = [];
        setselectionClient(response.data.client_id);
        console.log("check 1 =>" + selectionClient);
        // Set client and section name (same as before)

        // setSecName(response.data[0].secname);

        // Assuming the response is structured in a way that 'section' is an object with keys representing section IDs.
        Object.keys(response.data.section).forEach((sectionKey) => {
          console.log(sectionKey);
          response.data.section[sectionKey].forEach((item) => {
            const productData = item.product; // Use 'product' directly from the response
            newData.push({
              id: item.id, // Assuming 'project_details_id' is the unique identifier
              check: false,
              sku_code: item.sku_code,
              brand: productData.brand,
              product_desc: productData.product_desc,
              finish: productData.finish || "", // Default to empty string if not available
              image: productData.image || "", // Default to empty string or image path if not available
              quantity: item.qty,
              area: item.area,
              mrp: productData.mrp,
              discount_price: item.disc_price,
              discount_per: item.disc_per,
              gst: item.gst,
              total: item.total || 0, // Assuming 'totals' is the total price you want
              discount: "", // Default value or calculation can be added here
              selection: item.section_id,
              active: item.section_id === selectionId, // Compare with selectionId to set active
              selection_name: item.section_name,
              check_gst:item.gst,
              type: "update",
            });
          });
        });

        const selectedItem = newData.find((item) => item.selection === 1);

        const newSecName = selectedItem
          ? selectedItem.selection_name || ""
          : "";
        setSecName(newSecName);

        setTableData(newData);
        console.log(newData);
      } catch (error) {
        // Handle errors if any
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Ensure loading is set to false after the operation
      }
    };

    fetchData(); // Call the inner async function immediately
  }, []);

  //  Add Client
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log("start");
      const response = await UserSection.addClient(formData);
      if (response.status) {
        Swal.fire({
          icon: "success",
          title: "client",
          text: "Client added  successfully",
          timer: 3000, // Automatically close after 3 seconds
        });
        setFormData({
          client_name: "",
          site_add: "",
          afd: "",
          add_to: "",
          username: "",
        });
        ClientList();
      }
      console.log("end");
    } catch (error) {
      handleApiError(error, "Failed to fetch data. Please try again later.");
    }
  };
  // Login with id

  const viewclent = async (e) => {
    e.preventDefault();
    if (clientId != 0) {
      try {
        const response = await UserSection.clientLogin(clientId);
        if (response.status) {
          setData(response.data);
        }
      } catch (error) {
        handleApiError(error, "Failed to fetch data. Please try again later.");
      }
    } else {
      return 0;
    }
  };
  const ClientList = async () => {
    // e.preventDefault()
    try {
      const response = await UserSection.allClients();
      if (response.status) {
        setClient(response.data);
      }
    } catch (error) {
      handleApiError(error, "Failed to fetch data. Please try again later.");
    }
  };
  const handleSelectChange = (option) => {
    // Check if the option is valid
    if (option) {
      setselectionClient(option.value); // Set the selected client ID
    }
  };

  const options = client
    ? client.map((item) => ({
      value: item.id,
      label: `${item.client_name} - ${item.id}`,
    }))
    : [];

  const handlesku = async (e, id) => {
    const updatedItems = [...tableData];

    // Find the index of the object with the matching id
    const index = updatedItems.findIndex((item) => item.id === id);

    const resdata = {
      id: updatedItems[index].id,
      check: false,
      sku_code: e.target.value,
      brand: "",
      product_desc: "",
      finish: "",
      image: "",
      quantity: "",
      area: "",
      mrp: "",
      discount_price: "",
      discount_per: "",
      gst: "",
      total: 0,
      discount: 0,
      selection: selectionId,
      active: true,
      selection_name: secName,
    };
    // updatedItems = { ...updatedItems[index], resdata };
    updatedItems[index] = resdata;
    setTableData(updatedItems);
  };
  const handleKeyUp = async (e, id) => {
    const updatedItems = [...tableData];
    const index = updatedItems.length;
    try {
      // alert(e.target.value);
      if (e.target.value != 0) {
        const updatedItems = [...tableData];

        // Find the index of the object with the matching id
        const index = updatedItems.findIndex((item) => item.id === id);

        const response = await UserSection.productBySkeu(e.target.value);
        if (response.status) {
          // Create a copy of the array

          // Update the object's properties
          if (index !== -1) {
            let hasgst;
            if (gst == 1) {
              hasgst = response.data.gst;
            } else {
              hasgst = 0;
            }
            let ref_total = parseFloat((hasgst * response.data.mrp) / 100);
            ref_total += parseFloat(response.data.mrp);
            const getLartgest_Id = getLartgestId();
            const resdata = {
              id: getLartgest_Id,
              check: false,
              sku_code: response.data.sku_code,
              brand: response.data.brand,
              product_desc: response.data.product_desc,
              finish: response.data.finish,
              image: response.data.image,
              quantity: "",
              area: "null",
              mrp: response.data.mrp,
              discount_price: "",
              discount_per: "",
              gst: hasgst,
              total: ref_total,
              discount: 0,
              selection: selectionId,
              active: true,
              selection_name: secName,
              check_gst: response.data.gst,
            };

            updatedItems[index] = resdata;

            setTableData(updatedItems); // Update the state with the modified array
            //    setRowsId(rows_id + 1);
          }
        }
      }
    } catch (error) {
      handleApiError(error, "Failed to fetch data. Please try again later.");
    }
  };

  const Selection = async () => {
    try {
      const response = await UserSection.allselection();
      if (response.status) {
        setSelection(response.data);
      }
    } catch (error) {
      handleApiError(error, "Failed to fetch data. Please try again later.");
    }
  };

  const saveProjectData = async (e) => {
    let dataStatsu = false;
    dataStatsu = await errorValidation();
    if (dataStatsu == true) {
      if (!projectId) {
        Swal.fire({
          title: "Do you want to continue?",
          icon: "success",
          showCancelButton: true,
          confirmButtonText: "Yes, continue",
          cancelButtonText: "No, cancel",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
        }).then((result) => {
          if (result.isConfirmed) {
            saveConfimed();
            setLoading(true);
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            setLoading(false);
          }
        });
      } else {
        Swal.fire({
          title: "Do you want to Save?",
          icon: "success",
          showCancelButton: true,
          confirmButtonText: "Save New",
          cancelButtonText: "Update Existing",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
        }).then((result) => {
          if (result.isConfirmed) {
            saveConfimed();
            setLoading(true);
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            updateConfimed();
            setLoading(false);
          }
        });
      }
    } else {
      return 0;
    }
  };
  const saveConfimed = async () => {
    const filteredItems = [...tableData];
    const updatedItems = filteredItems.filter((item) => item.sku_code !== "");
    await setTableData(updatedItems);
    setLoading(true);
    const mergedState = {
      tableData, // Spread the properties of state1
      selectionId,
      selectionClient,
      amountType: amountType,
    };
    try {
      const response = await UserSection.saveProductData(mergedState);
      setLoading(false);
      if (response) {
        Swal.fire({
          title: "success",
          icon: "success",
          html: `
                        <div>
                        <label>Project added successfuly</label> </br>
                        <label>Project ID:</label>
                        <span>${response.project_id}</span>
                        </div>
                    `,

          showConfirmButton: true,
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            removeState();
            setClient("");
            setselectionClient("");
            setSecName("");
            setLoading(false);
            // document.getElementById('selectionName').value = '';
            // document.getElementById('clientId').value = '';
            // document.getElementById('sku_code');
            window.location.href = "/user/projects";
          }
          setLoading(false);
        });
      }
    } catch (error) {
      setLoading(false);
      handleApiError(error, "Failed to fetch data. Please try again later.");
    }
  };
  const updateConfimed = async () => {
    try {
      const filteredItems = [...tableData];
      const updatedItems = filteredItems.filter((item) => item.sku_code !== "");
      await setTableData(updatedItems);
      setLoading(true);
      const mergedState = {
        tableData, // Spread the properties of state1
        selectionClient,
        projectId,
      };
      const response = await UserSection.updateProductData(mergedState);
      if (response.status) {
        setLoading(false);
        Swal.fire({
          title: "success",
          icon: "success",
          html: `
                        <div>
                        <label>Project Update successfuly</label> </br>
                        <label>Project ID:</label>
                        <span>${response.project_id}</span>
                        </div>
                    `,

          showConfirmButton: true,
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            removeState();
            setClient("");
            setselectionClient("");
            setSecName("");
            setLoading(false);

            // document.getElementById('clientId').value = '';
            // document.getElementById('sku_code');
            window.location.href = "/user/projects";
          }
          setLoading(false);
        });
      }
    } catch (error) {
      setLoading(false);
      handleApiError(error, "Failed to fetch data. Please try again later.");
    }
  };
  const errorValidation = async () => {
    let updatedItems = [...tableData];
    if (selectionClient) {
      for (let i = 0; i < updatedItems.length; i++) {
        if (
          updatedItems[i].sku_code != "" &&
          updatedItems[i].selection_name == ""
        ) {
          Swal.fire({
            icon: "error",
            text: "Selection name required for " + updatedItems[i].selection,
            timer: 3000, // Automatically close after 3 seconds
          });
          return false;
        }
        if (
          (updatedItems[i].sku_code != "" && updatedItems[i].selection == "") ||
          updatedItems[i].selection == null
        ) {
          Swal.fire({
            icon: "error",
            text: "Selection required for " + updatedItems[i].selection,
            timer: 3000, // Automatically close after 3 seconds
          });
          return false;
        }
        // if (updatedItems[i].sku_code == '') {
        //     Swal.fire({
        //         icon: 'error',
        //         text: 'Sku code required for ' + updatedItems[i].selection,
        //         timer: 3000, // Automatically close after 3 seconds
        //     });
        //     return false;
        // }
        if (updatedItems[i].sku_code != "" && updatedItems[i].quantity == "") {
          Swal.fire({
            icon: "error",
            text: "Quantity required for " + updatedItems[i].selection,
            timer: 3000, // Automatically close after 3 seconds
          });
          return false;
        }
        if (updatedItems[i].sku_code != "" && updatedItems[i].area == "") {
          Swal.fire({
            icon: "error",
            text: "Area required for " + updatedItems[i].selection,
            timer: 3000, // Automatically close after 3 seconds
          });
          return false;
        }
        if (
          updatedItems[i].sku_code != "" &&
          updatedItems[i].discount_price == ""
        ) {
          if (amountType == "no") {
            return true;
          } else {
            Swal.fire({
              icon: "error",
              text: "Discount price required for " + updatedItems[i].selection,
              timer: 3000, // Automatically close after 3 seconds
            });
            return false;
          }
        }
      }
    } else {
      Swal.fire({
        icon: "error",
        text: "Client Id required",
        timer: 3000, // Automatically close after 3 seconds
      });
      return false;
    }
    return true;
  };

  const addRowNew = (newSelectionId = null) => {
    // Ensure the newSelectionId is set, fallback to selectionId if null
    if (newSelectionId === null) {
      newSelectionId = selectionId;
    }

    // Step 1: Count how many rows have the given selection
    let total_slot = tableData.filter(
      (row) => row.selection === newSelectionId
    ).length;

    // Step 2: Check if the row limit has been reached
    if (total_slot >= 300) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Rows Limit reached = 300",
      });
      return false; // Prevent adding a new row
    }

    // Step 3: Generate the new row ID based on your ID generation logic
    const getLartgest_Id = getLartgestId(); // Ensure this function returns the next available ID
    setRowsId(getLartgest_Id); // Update the rows_id state

    // Step 4: Create the new row object
    const newRow = {
      id: getLartgest_Id,
      check: false,
      sku_code: "",
      brand: "",
      brand_description: "",
      finish: "",
      image: "",
      quantity: "",
      area: "",
      mrp: "",
      discound_price: "",
      discound_per: "",
      gst: "",
      selection: newSelectionId,
      active: true,
      selection_name: "",
    };

    // Step 5: Update the table data (ensure state update is handled correctly)
    setTableData((prevTableData) => {
      // Use a functional update to ensure you're working with the most recent state
      return [...prevTableData, newRow]; // Append new row to the existing tableData
    });

    // Debugging output to verify the new row is added
    console.log(tableData);
  };

  const addRow = (newSelectionId = null) => {
    // e.preventDefault();
    var total_slot = 0;
    if (null == newSelectionId) {
      newSelectionId = selectionId;
    }
    for (let i = 0; i < tableData.length; i++) {
      if (tableData[i].selection == newSelectionId) {
        total_slot += 1;
      }
    }
    if (total_slot >= 300) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Rows Limit riched = 300 ",
      });
      return false;
    }
    // var row_id = tableData.length + 1;
    // let r_id = rows_id + 1;
    const getLartgest_Id = getLartgestId();
    console.log(getLartgest_Id);
    setRowsId(getLartgest_Id);

    const newRow = {
      id: getLartgest_Id,
      check: false,
      sku_code: "",
      brand: "",
      brand_description: "",
      finish: "",
      image: "",
      quantity: "",
      area: "",
      mrp: "",
      discound_price: "",
      discound_per: "",
      gst: "",
      selection: newSelectionId,
      active: true,
      selection_name: "",
    };
    setTableData([...tableData, newRow]);
    console.log(newRow);
  };

  const removeRow = (e, id) => {
    e.preventDefault();
    if (tableData.length <= 1) {
      return; // No rows to remove
    }
    let updatedItems = [...tableData];
    let u1 = [];
    for (let i = 0; i < updatedItems.length; i++) {
      if (updatedItems[i].check == true) {
        if (updatedItems.length <= 1) {
          return; // No rows to remove
        }
        // updatedItems = updatedItems.filter((item, index) => index !== i);
        updatedItems = updatedItems.filter((item) => !item.check);
      }
    }
    setTableData(updatedItems);
  };
  const resetState = () => {
    removeState();
    setSelectionID(0);
    setSecName();
    setselectionClient();
    setSub_Total(0);
    tableData[0].sku_code = "";
    window.location.href = "/user/projects";
  };
  const removeState = () => {
    tableData[0].sku_code = "";
    setTableData([
      {
        id: 1,
        check: false,
        sku_code: null,
        brand: "",
        brand_description: "",
        finish: "",
        image: "",
        quantity: "",
        area: "",
        mrp: "",
        discount_price: "",
        discount_per: "",
        gst: "",
        total: "",
        discount: "",
        selection: selectionId,
        active: true,
      },
    ]);
  };
  const handleCheckboxChange = (status, index) => {
    const updatedItems = [...tableData];
    // const index = updatedItems.findIndex(item => item.id === id);

    updatedItems[index].check = updatedItems[index].check ? false : true;
    setTableData(updatedItems);
    // selectedCheckbox([...setSelectedCheckbox, options]);
  };
  const changeformdataqtyOld = (e, index) => {

    if (e.target.value >= 0) {
      const updatedItems = [...tableData];

      const all_total = sub_Total;
      // const index = updatedItems.findIndex(item => item.id === id);
      updatedItems[index].quantity = e.target.value;
      let roundTotal = parseFloat(updatedItems[index].mrp * e.target.value);

      let gst = parseFloat((roundTotal * updatedItems[index].gst) / 100);
      roundTotal += gst;
      // const roundTotal = updatedItems[index].mrp * updatedItems[index].quantity * updatedItems[index].gst / 100;
      // roundTotal+=gst;
      updatedItems[index].total = parseFloat(
        roundTotal - updatedItems[index].discount
      );
      // updatedItems[index].total += updatedItems[index].total +gst;
      // all_total +  updatedItems[index].total;
      // updatedItems[index].total = updatedItems[index].total - updatedItems[index].discount;
      setSub_Total(all_total + updatedItems[index].total);
      setTableData(updatedItems);
      updatePrice(index);
    } else {
      alert("1");
      const updatedItems = [...tableData];
      let roundTotal = parseFloat(updatedItems[index].mrp * 1);
      let gst = parseFloat((roundTotal * updatedItems[index].gst) / 100);
      roundTotal += gst;

      updatedItems[index].total = roundTotal;
      setTableData(updatedItems);
      return 0;
    }
  };

  const changeformdataqty = (e, id) => {
    const newQuantity = e.target.value;

    // Validate the new quantity
    if (newQuantity < 0) {
      alert("Quantity must be greater than or equal to 0.");
      return resetItemToDefault(id); // Reset the item to default (quantity 1) if invalid
    }

    // Find the index of the item using the provided id
    const index = tableData.findIndex(item => item.id === id);

    if (index === -1) {
      console.error("Item not found with the provided ID");
      return; // Handle case where the item is not found
    }
    console.log(id);
    console.log(index);
    // Get the item to update
    const updatedItems = [...tableData];
    const item = updatedItems[index];
    console.log(item);
    // Update the item's quantity and recalculate total
    item.quantity = newQuantity;
    item.total = calculateItemTotal(item.mrp, newQuantity, item.gst, item.discount);

    // Update tableData state and recalculate the subtotal
    setTableData(updatedItems);
    updateSubtotal(updatedItems);
    updatePrice(index); // Assuming this updates prices based on some other logic
  };

  // Utility function to calculate item total including GST and discount
  const calculateItemTotal = (mrp, quantity, gst, discount) => {
    let baseTotal = mrp * quantity;
    let gstAmount = (baseTotal * gst) / 100;
    let total = baseTotal + gstAmount - discount;
    return parseFloat(total.toFixed(2)); // Round to 2 decimal places for accuracy
  };

  // Utility function to reset an item when quantity is invalid
  const resetItemToDefault = (id) => {
    // Find the index of the item using the provided id
    const index = tableData.findIndex(item => item.id === id);

    if (index === -1) {
      console.error("Item not found with the provided ID");
      return; // Handle case where the item is not found
    }

    const updatedItems = [...tableData];
    const item = updatedItems[index];

    // Default quantity is 1 if invalid input is detected
    item.quantity = 1;
    item.total = calculateItemTotal(item.mrp, 1, item.gst, item.discount);

    // Update tableData state and recalculate the subtotal
    setTableData(updatedItems);
    updateSubtotal(updatedItems);
  };

  // Utility function to calculate the subtotal of all items
  const updateSubtotal = (items) => {
    const total = items.reduce((acc, item) => acc + item.total, 0);
    setSub_Total(parseFloat(total.toFixed(2)));  // Round to 2 decimal places
  };

  const updatePrice = (index) => {
    const updatedItems = [...tableData];
    const all_total = sub_Total;
    let qty = updatedItems[index].quantity;

    let roundTotal = parseFloat(updatedItems[index].mrp * qty);

    let gst = parseFloat((roundTotal * updatedItems[index].gst) / 100);
    roundTotal += gst;
    // const roundTotal = updatedItems[index].mrp * updatedItems[index].quantity * updatedItems[index].gst / 100;
    // roundTotal+=gst;
    // updatedItems[index].total = parseFloat((roundTotal) - (updatedItems[index].discount));
    let sum = parseFloat((roundTotal * updatedItems[index].discount_per) / 100);
    updatedItems[index].total = parseFloat(roundTotal - sum);
    // updatedItems[index].total += updatedItems[index].total +gst;
    // all_total +  updatedItems[index].total;
    // updatedItems[index].total = updatedItems[index].total - updatedItems[index].discount;
    setSub_Total(all_total + updatedItems[index].total);
    setTableData(updatedItems);
  };
  const changeAmountType = (e, id) => {
    let total = sub_Total;
    // let value = parseFloat(e.target.value);
    let value = e.target.value;
    let dis = 0;
    let mrp = 0;
    const sku_code = e.target.value;
    const regex = /^[0-9.]*$/;
    const index = tableData.findIndex(item => item.id === id);
    let updatedItems = [...tableData];

    if (e.target.value != "") {
      if (value > -1) {
        let qty = updatedItems[index].quantity
          ? parseInt(updatedItems[index].quantity)
          : 1;
        let roundTotal = parseFloat(parseFloat(updatedItems[index].mrp) * qty);

        let gst = parseFloat(
          (roundTotal * parseFloat(updatedItems[index].gst)) / 100
        );
        roundTotal += gst;

        if (amountType == "no") {
          return 0;
        } else if (amountType == "price") {
          // updatedItems[index].total =  updatedItems[index].total - total;
          updatedItems[index].discount_price = parseFloat(
            (value * 100) / updatedItems[index].mrp
          );
          updatedItems[index].discount = value;
          let sum = parseFloat(
            (roundTotal * updatedItems[index].discount_price) / 100
          );
          updatedItems[index].total = parseFloat(roundTotal - sum);
          updatedItems[index].sub_Total = updatedItems[index].total;
          dis =
            (updatedItems[index].discount_price * 100) /
            updatedItems[index].mrp +
            "00";
          updatedItems[index].discount_per =
            (value * 100) / updatedItems[index].mrp;
          total = total + updatedItems[index].sub_Total;
          updatedItems[index].discount_price = e.target.value;
        } else {
          updatedItems[index].discount_per = value;
          let totla_per = parseFloat((roundTotal * value) / 100);
          updatedItems[index].discount = totla_per;

          updatedItems[index].sub_Total = parseFloat(
            updatedItems[index].sub_Total + updatedItems[index].total
          );
          dis =
            (updatedItems[index].discount_per * updatedItems[index].mrp) / 100;

          updatedItems[index].discount_price = dis;
          // updatedItems[index].discount_per = e.target.value;
          // total = total + updatedItems[index].sub_Total;
        }

        setTableData(updatedItems);

        setSub_Total(updatedItems[index].sub_Total);
        updatePrice(index);
      }

      // }
      else {
        alert("Please select positive value");
        return 0;
      }
    } else {
      updatedItems[index].discount_per = "";
      updatedItems[index].discount_price = "";
      // updatedItems.total =  updatedItems.total;
      setTableData(updatedItems);
    }
  };
  const changeGstOld = (id) => {
    setGst(id);
    let updatedItems = [...tableData];
    const lastItemIndex = updatedItems.length - 1;
    const lastItem = updatedItems[lastItemIndex];
    const total_gst =
      parseFloat(
        updatedItems[lastItemIndex].mrp * updatedItems[lastItemIndex].check_gst
      ) / 100;
    if (gst == 1) {
      updatedItems[lastItemIndex].total -= total_gst;
      updatedItems[lastItemIndex].gst = 0;
      // Now, set the updated items back to state
      setTableData(updatedItems);
    } else {
      updatedItems[lastItemIndex].total += total_gst;

      updatedItems[lastItemIndex].gst = updatedItems[lastItemIndex].check_gst;
      // Now, set the updated items back to state
      setTableData(updatedItems);
    }
  };
  const changeGst = (id) => {
    setGst(id);
    let updatedItems = [...tableData];  // Create a copy of the table data
    updatedItems = updatedItems.map((item, index) => {
      const total_gst = parseFloat(item.mrp * item.check_gst) / 100;
      if (gst == 1) {
        item.total -= total_gst;
        item.gst = 0;
      } else {
        item.total += total_gst;
        item.gst = 18;
      }
      return item;
    });
    setTableData(updatedItems);
    console.log(tableData);
  };

  const setArea = (e, id) => {
    const updatedItems = [...tableData];
    const index = tableData.findIndex(item => item.id === id);
    updatedItems[index].area = e;
    setTableData(updatedItems);
  };

  const changeSelectionNew = (e) => {
    let Row = 0;
    const newSelectionId = e.target.value;

    const updatedItems = tableData.map((item) => {
      // Step 2: Deactivate all items that don't match the selected selection ID
      const isSelected = item.selection == newSelectionId;

      if (isSelected) {
        Row += 1;
      }
      // Step 3: Update the `active` property based on the selection ID
      const updatedItem = {
        ...item, // Copy all properties of the current row
        active: isSelected, // Set active to true if it matches
      };

      return updatedItem;
    });

    // Step 4: Calculate additional state values (e.g., totalSlots, secName, etc.)
    const selectedItem = updatedItems.find(
      (item) => item.selection == newSelectionId
    );
    const newSecName = selectedItem ? selectedItem.selection_name || "" : "";
    const newSubTotal = selectedItem ? selectedItem.sub_Total || "" : "";

    let totalSlots = tableData.filter((item) => item.brand === "").length;

    // Step 5: Use the functional state update to update the table data and other state values
    setTableData(updatedItems); // This triggers a re-render with updated data
    setSecName(newSecName); // Update the selected section name
    setSub_Total(newSubTotal); // Update the selected subtotal
    setSelectionID(newSelectionId); // Update the selected ID
    if (!selectedItem) {
      addRow(newSelectionId);
    }
  };

  const changeSelection = (e) => {
    let Row = 0;
    const newSelectionId = e.target.value;
    console.log(newSelectionId);
    let updatedItems = [...tableData];

    let current_sel = "";
    var total_slots = "";
    setSecName("");
    setSelectionID(newSelectionId);
    for (let i = 0; i < updatedItems.length; i++) {
      // updatedItems[i].selection = newSelectionId
      if (updatedItems[i].brand == "") {
        total_slots += 1;
      }
      if (updatedItems[i].selection != newSelectionId) {
        updatedItems[i].active = false;
        setSecName("");
        setSub_Total("");
      } else if (updatedItems[i].selection == newSelectionId) {
        setSecName("");

        updatedItems[i].active = true;
        current_sel = updatedItems[i].selection_name
          ? updatedItems[i].selection_name
          : "";
        setSub_Total(updatedItems[i].sub_Total);
      }
      if (updatedItems[i].selection == newSelectionId) {
        // updatedItems[i].selection = newSelectionId
        Row += 1;
      }
    }

    setTableData(updatedItems);
    // console.log(tableData);
    setSecName(current_sel);
    if (Row <= 0) {
      if (is_Empty(newSelectionId)) {
        addRow(newSelectionId);
      }
    }
  };
  const is_Empty = (newSelectionId) => {
    let updatedItems = [...tableData];
    let status = true;
    for (let i = 0; i < updatedItems.length; i++) {
      if (updatedItems[i].selection == newSelectionId) {
        status = false;
      }
      if (status == false) {
        break;
      }
    }
    return status;
  };
  const changesectionName = (e) => {
    const value = e.target.value;
    setSecName(value);

    let updatedItems = [...tableData];
    for (let i = 0; i < updatedItems.length; i++) {
      if (parseInt(updatedItems[i].selection) === parseInt(selectionId)) {
        updatedItems[i].selection_name = value;
      }
    }

    setTableData(updatedItems);
  };
  const resetClient = (e) => {
    e.preventDefault();
    // viewclent(e);
    setData();
    setclientId(0);
    if (dropdownRef.current) {
      dropdownRef.current.selectedIndex = 0;
    }
  };

  const editProject = async () => {
    try {
      const editTable = await UserSection.editProject(projectId);
    } catch (error) {
      setLoading(false);
      handleApiError(error, "Failed to fetch data. Please try again later.");
    }
  };

  // upload excel
  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Access the uploaded file
    setFiles(file);

    // console.log(" files data =>" + JSON.stringify(file));

    const reader = new FileReader();
    let allSheetData = [];

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      workbook.SheetNames.forEach((sheetName, index) => {
        const sheet = workbook.Sheets[sheetName];

        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        if (index == 0) {
          allSheetData[sheetName] = jsonData.slice(0);
        } else {
          allSheetData[sheetName] = jsonData.slice(0);
        }
      });

      const sectionsArray = Object.entries(allSheetData);
      // const dataString  = JSON.stringify(allSheetData);
      importcsvfile1(sectionsArray);
      //   console.log("compl => " + sectionsArray.length);
      setExcelData(sectionsArray);
      setError("");
    };

    reader.readAsArrayBuffer(file);
  };

  // upload data
  const importcsvfile = () => {
    setupload(true);
    console.log(files);
  };
  const importcsvfile1 = async (data) => {
    try {
      const updatedItems = [...tableData];
      setLoading(true);
      if (excelData) {
        const large_id = getLartgestId();
        const importData = await UserSection.excelImport(data);
        setLoading(false);
        // setSecName(importData.data[0].sheet_name);
        // setSelectionID(importData.data[0].section_id)
        let ref_total = 0;
        const has_gst = 0;

        const newData = importData.data.map((item, index) => ({
          has_gst: gst == 1 ? parseFloat(item.gst) : 0,
          ref_total:
            gst == 1 ? (parseFloat(item.gst) * parseFloat(item.mrp)) / 100 : 0,
          id: large_id + (index + 1),
          check: false,
          sku_code: item.sku_code,
          brand: item.brand,
          product_desc: item.product_desc,
          finish: item.finish,
          image: item.image, // Assuming image is not provided in the response
          quantity: item.qty, // You can populate these fields if available in the response
          area: item.area, // or leave them empty
          mrp: item.mrp,
          discount_price: item.discount_per
            ? (parseFloat(item.discount_per) * parseFloat(item.mrp)) / 100
            : item.discount_price,
          // discount_per: ((parseFloat(item.discount_price)) * 100) / parseFloat(item.mrp),
          discount_per: item.discount_price
            ? (parseFloat(item.discount_price) * 100) / parseFloat(item.mrp)
            : parseFloat(item.discount_per),
          gst: gst == 1 ? item.gst : 0,
          total:
            gst == 1 ? parseFloat(item.gst_total) : parseFloat(item.non_gst),

          //  total: ((parseFloat(item.total) * (parseFloat(has_gst)) - parseFloat(item.discount_price))),
          discount: "",
          selection: item.section_id ? item.section_id : selectionId, // Assuming selectionId is defined elsewhere
          active: item.section_id == selectionId ? true : false,
          selection_name: secName,
          check_gst:item.gst,
        }));
        console.log(newData);
        if (
          updatedItems.length > 0 &&
          updatedItems[0] &&
          updatedItems[0].sku_code === ""
        ) {
          setTableData(newData);
          setRowsId(tableData.length);
        } else {
          const index = updatedItems.length;
          updatedItems[index] = newData;
          setRowsId(26);

          // setTableData(prevData => [...prevData, ...newData]);
        }
        if (gst == 1) {
          setexcludeGst(true);
        } else {
          setincludeGst(true);
        }
      } else {
        setError("Please select a file."); // Set error message
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      // Handle errors here
    }
  };

  const getLartgestId = () => {
    const largestId = tableData.reduce((maxId, item) => {
      return Math.max(maxId, item.id);
    }, -Infinity);
    return largestId + 1;
  };

  const downloadcsv = async (e) => {
    try {
      e.preventDefault();
      const url = "https://testingruminate.co.in/public/excel/sku_data.xlsx";
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "sku_data.xlsx";
      anchor.click();
    } catch (error) {
      setLoading(true);
      console.log(error);
      // handleApiError(error, 'Failed to fetch data. Please try again later.');
    }
  };

  const place = selectionId ? selectionId : 1;
  const concatenatedString = `Enter selection name ${selectionId}`;

  let total_amount = 0;
  console.log("check 2nd => " + selectionClient);

  return (
    <>
      <UserTopbar heading="Projects" />
      {loading && <Loader />}

      {/* <section className="md:flex">
                <div className="flex-1 after:content-[''] after:absolute after:w-0.5 after:bg-gray-500 add-client" >
                    <h2 className="p-3 font-semibold text-xl mr-2">Add New Client Details</h2>
                    <form action="" method="post" className="px-5">
                        <div className="flex mb-2">
                            <label htmlFor="clientName" className="flex-1">Client Name</label>
                            <input class="flex-1 form-text rounded " type="text" id="client_name" name="client_name" placeholder="Enter your username" value={formData.client_name} onChange={handleChange} ></input>
                        </div>
                        <div className="flex mb-2">
                            <label htmlFor="siteAddress" className="flex-1">Site Address:</label>
                            <input class="flex-1 form-text rounded " type="text" id="site_add" name="site_add" placeholder="Enter site address" value={formData.site_add} onChange={handleChange}></input>
                        </div>
                        <div className="flex mb-2">
                            <label htmlFor="afd" className="flex-1">Architect Firm Details:</label>
                            <input class="flex-1 form-text rounded " type="text" id="afd" name="afd" placeholder="Enter architect firm details" value={formData.afd} onChange={handleChange}></input>
                        </div>
                        <div className="flex mb-2">
                            <label htmlFor="address" className="flex-1">Address To:</label>
                            <input class="flex-1 form-text rounded " type="text" id="add_to" name="add_to" placeholder="Enter address" value={formData.add_to} onChange={handleChange} ></input>
                        </div>
                        <button onClick={handleSubmit} type="button" className="bg-blue-950 p-3 text-white rounded">Add New Client</button>
                    </form>
                </div>

                <div className="flex-1">
                    <h2 className="p-3  view_client font-semibold ">View Client Detail</h2>
                    <form action="" method="get" className="px-5 justify-center view_client">
                        <label htmlFor="clientId">Client Id</label>
                        <br></br>
                        {
                            <select class='form-select rounded text-center w-full' onChange={handleSelectChange} ref={dropdownRef} defaultValue={selectionId} >
                                <option value="option1">{selectedOption}</option>
                                {client ? ((client.map((item) => (

                                    <option value={item.id} > {item.client_name} - {item.id}</option>

                                )))) : (
                                    "No data Found"
                                )}
                            </select>
                        }
                        <div className="flex justify-center gap-4 pt-5">

                            <button onClick={viewclent} type="button" className="bg-blue-950 p-3 text-white rounded">Submit</button>
                            <button type="button" className="bg-blue-950 p-3 text-white rounded" onClick={(e) => resetClient(e)}>Reset</button>
                        </div>
                    </form>
                    <div class="client-details text-align:-webkit-center" >
                        <table className="w-1/2 client-details">
                            {
                                data && (
                                    <tbody>
                                        <tr>
                                            <td>Id</td>
                                            <td>{data.id}</td>
                                        </tr>
                                        <tr>
                                            <td>Clent Name</td>
                                            <td>{data.client_name}</td>
                                        </tr>
                                        <tr>
                                            <td>Site Address:</td>
                                            <td>{data.site_add}</td>
                                        </tr>
                                        <tr>
                                            <td>Addressed To:</td>
                                            <td>{data.add_to}</td>

                                        </tr>
                                        <tr>
                                            <td>Architect Firm Details:	</td>
                                            <td>{data.afd}</td>
                                        </tr>


                                    </tbody>

                                )
                            }
                        </table>
                    </div>
                </div>
                <br />
            </section> */}
      <div className=" fixed bg-white min-w-[94%] -mt-4 h-[12rem]  border-b border-gray-500">
        <div className="mt-5 flex justify-between items-center ">
          <Autocomplete onSelected={handleSelected} />
          <div className="flex gap-5">
            <button
              style={{
                width: "255px",
              }}
              type="button"
              className="bg-blue-950 text-white p-3 rounded"
              onClick={(e) => downloadcsv(e)}
            >
              Download Format
            </button>
            <div>
              <input
                type="file"
                className="bg-blue-950 text-white p-3 rounded"
                onChange={handleFileChange}
              />
              {/* <button
                                type="button"
                                className="bg-blue-950 text-white p-3 rounded"
                                onClick={importcsvfile}
                                style={{ marginLeft: '10px' }} // Add margin to the button
                            >
                                Upload File
                            </button> */}
            </div>
          </div>
        </div>

        <section className="md:flex justify-between items-center gap-x-3 w-full p-2 mt-3 ">
          <select
            className="flex-1 py-4 form-select rounded text-center"
            onChange={(e) => changeSelection(e)}
            name="selection"
            id="selection"
            required
            value={selectionId}
          >
            {selection &&
              selection.map((item) => (
                <option
                  key={item.id}
                  value={item.id}
                  className={item.id === selectionId ? "selected" : ""}
                >
                  {item.name}
                </option>
              ))}
          </select>
          <input
            type="text"
            name={`sectionName${selectionId}`}
            id={`sectionName${selectionId}`}
            className="flex-1 py-4 form-text rounded text-black-500 text-center"
            onChange={(e) => changesectionName(e)}
            value={secName}
            placeholder="Enter Selection Name*"
          />
          {/* <input type="text" name="selectionClient" id="selectionClient" className="flex-1 form-text rounded text-black-500 text-center" placeholder="Enter Client ID" onChange={(e) => setselectionClient(e.target.value)} value={selectionClient} /> */}
          {/* gst */}
          <div className="flex-1">
            <Select
              className="py-4 "
              value={
                selectionClient
                  ? options.find((option) => option.value === selectionClient) // Find the correct option
                  : null
              }
              onChange={handleSelectChange}
              options={options}
              placeholder="Select Client"
              isSearchable
              styles={{
                control: (provided) => ({
                  ...provided,
                  // border: "none", // Remove default border
                  // boxShadow: "none", // Remove shadow
                  padding: "0.1rem", // Add padding
                  borderRadius: "0.28rem", // Tailwind rounded-md
                  backgroundColor: "white",
                  borderColor: "#4A5568",
                  paddingTop: "0.6rem ",
                  paddingBottom: "0.6rem",
                }),
              }}
            />
          </div>

          <fieldset className="border border-gray-300 rounded-md p-3">
            <legend className="text-gray-700 font-medium">GST * </legend>
            <div className="flex gap-x-3">
              <div className="flex items-center gap-x-1">
                <input
                  type="radio"
                  className="form-checkbox  w-5 text-primary border-2 border-gray-300 rounded-md focus:ring-primary"
                  name="get"
                  value="1"
                  onChange={(e) => changeGst(1)}
                  checked={gst === 1 ? true : false}
                  disabled={includeGst}
                ></input>
                <label htmlFor="discountPercent">Inclusive </label>
              </div>
              <div className="flex items-center gap-x-1">
                <input
                  type="radio"
                  className="form-checkbox  w-5 text-primary border-2 border-gray-300 rounded-md focus:ring-primary"
                  name="get"
                  value="0"
                  onChange={(e) => changeGst(0)}
                  checked={gst === 0 ? true : false}
                  disabled={excludeGst}
                ></input>
                <label htmlFor="discountPrice"> Exclusive </label>
              </div>
            </div>
          </fieldset>

          <fieldset className="border border-gray-300 rounded-md p-3">
            <legend className="text-gray-700 font-medium">Discount * </legend>
            <div className="flex gap-x-3">
              <div className="flex items-center gap-x-1">
                <input
                  type="radio"
                  className="form-checkbox  w-5 text-primary border-2 border-gray-300 rounded-md focus:ring-primary"
                  name="discount"
                  value="no"
                  onChange={(e) => setAmountType("no")}
                  checked={amountType === "no" ? true : false}
                ></input>
                <label htmlFor="discountPercent">No </label>
              </div>
              <div className="flex items-center gap-x-1">
                <input
                  type="radio"
                  className="form-checkbox  w-5 text-primary border-2 border-gray-300 rounded-md focus:ring-primary"
                  name="discount"
                  value="price"
                  onChange={(e) => setAmountType("price")}
                  checked={amountType === "price" ? true : false}
                ></input>
                <label htmlFor="discountPrice"> Price</label>
              </div>
              <div className="flex items-center gap-x-1">
                <input
                  type="radio"
                  className="form-checkbox  w-5 text-primary border-2 border-gray-300 rounded-md focus:ring-primary"
                  name="discount"
                  value="per"
                  onChange={(e) => setAmountType("percentage")}
                  checked={amountType === "percentage" ? true : false}
                ></input>
                <label htmlFor="discountPercent"> %</label>
              </div>
            </div>
          </fieldset>
        </section>
      </div>

      <section className="overflow-x-auto w-full mt-[11.6rem] ">
        <table className="productTable w-full text-center ...">
          <thead>
            <tr className="bg-blue-950 text-white">
              <th className="border border-slate-600 ...">+/-</th>
              <th className="border border-slate-600 ...">SKU Code</th>
              <th className="border border-slate-600 ...">Brand</th>
              <th className="border border-slate-600 ...">Brand Description</th>
              <th className="border border-slate-600 ...">Finish</th>
              <th className="border border-slate-600 ...">Product Image</th>
              <th className="border border-slate-600 ...">Quantity*</th>
              <th className="border border-slate-600 ...">Area*</th>
              <th className="border border-slate-600 ...">MRP</th>
              <th className="border border-slate-600 ...">Discount Price</th>
              <th className="border border-slate-600 ...">Discount %</th>
              <th className="border border-slate-600 ...">GST</th>
              <th className="border border-slate-600 ...">Total</th>
            </tr>
          </thead>
          <tbody>
            {tableData
              .filter((row) => row.active && row.selection == selectionId)
              .map(function (row, index) {
                if (row.active) {
                  total_amount += parseFloat(row.total);
                  return (
                    <tr key={row.id}>
                      <td className="border-b border-gray-300 p-2">
                        <input
                          type="checkbox"
                          class="orm-checkbox h-5 w-5 text-primary border-2 border-gray-300 rounded-md focus:ring-primary"
                          onChange={(e) =>
                            handleCheckboxChange(e.target.value, index)
                          }
                        ></input>
                      </td>

                      {row.sku_code ? (
                        <td>
                          <Tooltip title={row.sku_code} arrow>
                            <input
                              class="px-3 py-1 text-gray-700 border rounded-md w-16"
                              id="sku_code"
                              type="text"
                              onKeyUp={(e) => handleKeyUp(e, row.id)}
                              size="4"
                              value={row.sku_code}
                              disabled
                              style={{ borderRadius: "14px" }}
                            ></input>
                          </Tooltip>
                        </td>
                      ) : (
                        <td>
                          <Tooltip title={row.sku_code} arrow>
                            <input
                              class="px-3 py-1 text-gray-700 border rounded-md w-16"
                              id="sku_code"
                              type="text"
                              onKeyUp={(e) => handleKeyUp(e, row.id)}
                              size="4"
                              style={{ borderRadius: "14px" }}
                            ></input>
                          </Tooltip>
                        </td>
                      )}

                      <td>{row.brand}</td>
                      <td size="1" className="w-40">
                        {row.product_desc}
                      </td>
                      <td>{row.finish}</td>
                      {/* <td>{row.image}</td> */}
                      <td className="w-24 h-24">
                        {" "}
                        <img src={row.image} />
                      </td>
                      <td>
                        <input
                          className="px-3 py-1 text-gray-700 border rounded-md w-16"
                          type="text"
                          id=""
                          name="qty"
                          value={row.quantity}
                          onChange={(e) => changeformdataqty(e, row.id)}
                          size="3"
                        ></input>
                      </td>
                      <td className="text-justify ">
                        <input
                          type="radio"
                          name={"area" + [row.id]}
                          onChange={() => setArea("Shower", row.id)}
                          value={row.area}
                          checked={row.area === "Shower"}
                          className="text-center"
                        ></input>{" "}
                        Shower <br />
                        <input
                          type="radio"
                          name={"area" + [row.id]}
                          onChange={() => setArea("Basin", row.id)}
                          value="Basin"
                          checked={row.area === "Basin"}
                        ></input>{" "}
                        Basin
                        <br />
                        <input
                          type="radio"
                          name={"area" + [row.id]}
                          onChange={(e) => setArea("Ewc", row.id)}
                          value="Ewc"
                          checked={row.area === "Ewc"}
                        ></input>{" "}
                        EWC <br />
                        <input
                          type="radio"
                          name={"area" + [row.id]}
                          onChange={() => setArea("Kitchen", row.id)}
                          value="Kitchen"
                          checked={row.area === "Kitchen"}
                        ></input>{" "}
                        Kitchen
                        <br />
                        <input
                          type="radio"
                          name={"area" + [row.id]}
                          onChange={(e) => setArea("Faucets", row.id)}
                          value="Faucets"
                          checked={row.area === "Faucets"}
                        ></input>{" "}
                        Faucets
                      </td>
                      <td>{row.mrp}</td>
                      <td className="w-24">
                        <input
                          className="px-3 py-1 text-gray-700 border rounded-md w-16"
                          type="text"
                          id=""
                          name=""
                          disabled={amountType === "price" ? false : true}
                          onChange={(e) => changeAmountType(e, row.id)}
                          size="3"
                          value={row.discount_price}
                        ></input>
                      </td>
                      <td className="w-24 ">
                        <input
                          className="px-3 py-1 text-gray-700 border rounded-md w-16"
                          type="text"
                          id=""
                          name=""
                          disabled={amountType == "percentage" ? false : true}
                          onChange={(e) => changeAmountType(e, row.id)}
                          value={row.discount_per}
                          size="3"
                        ></input>
                      </td>
                      <td>{row.gst}</td>
                      <td>{row.total ? row.total.toFixed(2) : ""}</td>
                      {/* <td>{row.selection}</td> */}
                    </tr>
                  );
                }
              })}
          </tbody>
          <tbody>
            <tr>
              <td
                colSpan={12}
                className="text-left font-semibold border border-slate-600"
              >
                Total Amount :
              </td>
              <td className="font-bold">{total_amount.toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
        <div className="flex justify-center gap-x-5 py-4">
          {/* <button type="button" className="bg-blue-950 text-white p-2 rounded">Add Row</button>
                    <button type="button" className="bg-blue-950 text-white p-2 rounded">Delete</button>
                    <button type="button" className="bg-blue-950 text-white p-2 rounded">Reset</button>
                    <button type="button" className="bg-blue-950 text-white p-2 rounded">Save</button> */}
          <button
            className="bg-blue-950 text-white p-2 rounded w-32	"
            onClick={() => addRow(null)}
          >
            Add Row
          </button>
          <button
            className="bg-blue-950 text-white p-2 rounded w-32	"
            onClick={(e) => removeRow(e)}
          >
            Delete Rows
          </button>
          <button
            className="bg-blue-950 text-white p-2 rounded w-32	"
            onClick={(e) => resetState(e)}
          >
            Reset{" "}
          </button>
          <button
            onClick={saveProjectData}
            className="bg-blue-950 text-white p-2 rounded w-32"
          >
            {projectId ? "Update" : "Save"}
          </button>
        </div>
      </section>
    </>
  );
};

export default UserProjects;
